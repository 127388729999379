$( document ).ready(function() {
  //admin_edit_window
  $('.admin_edit_window_be .desktop_windows').on('click', function(event){
    admin_edit_window();
    $(this).addClass('selected');
    $('body').addClass('desktop_windows');
  });
  $('.admin_edit_window_be .tablet_android').on('click', function(event){
    admin_edit_window();
    $(this).addClass('selected');
    $('body').addClass('tablet_android');
  });
  $('.admin_edit_window_be .phone_android').on('click', function(event){
    admin_edit_window();
    $(this).addClass('selected');
    $('body').addClass('phone_android');
  });
  function admin_edit_window(){
    $('.admin_edit_window_be i').each(function(){
      $(this).removeClass('selected');
    });
    $('body').removeClass('desktop_windows');
    $('body').removeClass('tablet_android');
    $('body').removeClass('phone_android');
  }
})
